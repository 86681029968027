<template>
  <!-- 系统参数列表 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />

    <handleCompareDialog
      :openDialog.sync="openHandleCompareDialog"
      :compareFuncId="curCompareFuncId"
      @getList="update"
    />
    <!-- <c-dialog title="回收站" :width="1000" :showDialog.sync="recycleBinDialog">
      <template #content>
        <el-table
          class="marT20"
          :data="recycleBinList"
          style="width: 100%"
          @selection-change="recycleBinSelectionChange"
          height="500"
          border
        >
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="compareFuncName"
            label="功能名称"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="sortNo"
            label="排序"
            width="180"
          >
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
        </el-table>
        <TablePagination
          :page.sync="dialogQueryParams.pageNum"
          :limit.sync="dialogQueryParams.pageSize"
          :total="dialogTotal"
          @pagination="getList"
        ></TablePagination>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
        <el-button size="mini" type="primary" @click="restoreFn"
          >还 原</el-button
        >
      </template>
    </c-dialog> -->
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { delProduct, isOnlineProduct } from '@/api/tenant/product/saas'
import { compareList, delCompare } from '@/api/tenant/product/compare'
import TablePage from '@/components/tablePage/index.vue'
import handleCompareDialog from '@/views/tenant/product/compare/components/handleCompareDialog' // 操作(新增/编辑)系统产品功能弹窗
import CDialog from '@/components/dialogTemplate/dialog/index.vue'

export default {
  name: 'TenantList',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    handleCompareDialog,
    CDialog
  },
  dicts: ['sys_normal_disable'],
  data () {
    return {
      options: {
        loading: true,
        check: [],
        mutiSelect: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          compareFuncName: undefined //产品名称
        },
        defaultBody: {
          productType: 1
        },
        getListApi: compareList,
        refreshTable: true,
        defaultExpandAll: false,
        treeProps: {
          children: 'productCompareResps',
          hasChildren: 'hasChildren'
        },
        title: '产品列表',
        rowKey: 'compareFuncId',
        search: [
          {
            label: '功能名称',
            type: 'input',
            tip: '请输入功能名称',
            model: '',
            filter: 'compareFuncName'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            type: 'primary',
            label: '新增',
            icon: 'el-icon-plus'
          },
          {
            click: 'menu',
            label: '展开/折叠',
            type: 'success',
            icon: 'el-icon-sort'
          },
          // {
          //   click: 'start',
          //   label: '启用',
          //   alertText: '确认要启用选中方案吗？',
          //   btnType: 'dropdown',
          //   other: [
          //     { click: 'close', label: '禁用', alertText: '确认要禁用选中方案吗？' }
          //   ],
          //   disabled: () => !this.options?.check?.length
          // },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete'
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'compareFuncName',
            type: 'link',
            click: 'link',
            label: '功能名称'
          },
          { prop: 'remark', label: '备注' },
          { prop: 'sortNo', label: '排序' },
          { prop: 'createTime', label: '创建时间' }
        ],
        list: []
      },

      openHandleCompareDialog: false,
      curCompareFuncId: ''
    }
  },
  methods: {
    update () {
      this.$refs.tablePage.getList()
    },

    async handleEvent (type, row) {
      switch (type) {
        case 'link':
          this.curCompareFuncId = row.compareFuncId
          this.openHandleCompareDialog = true
          break
        case 'add':
          this.curCompareFuncId = undefined
          this.openHandleCompareDialog = true
          break
        case 'del':
          const names = this.options.check.map(item => item.compareFuncName)
          const ids = this.options.check.map(item => item.compareFuncId)
          this.$confirm(`确定删除${names.join(',')}功能`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              await delCompare(ids)
              this.$message.success('删除成功')
              await this.$refs.tablePage.getList()
            })
            .catch(() => {})
          break
        case 'menu':
          this.options.refreshTable = false
          this.options.defaultExpandAll = !this.options.defaultExpandAll
          this.$nextTick(() => {
            this.options.refreshTable = true
          })
          break
      }
    }
  }
}
</script>
