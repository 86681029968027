<template>
  <!--会员充值规则 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
    <c-dialog title="批量修改到期时间" :width="500" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item label="到期时间" label-width="120px" prop="validEndTime">
            <el-date-picker v-model="form.validEndTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handSubmite">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import TablePage from '@/components/tablePage' //表格页面组件
import {
  listTenantProductBookDetail,
  stopUsingByProductBookDetail,
  batchUpdateValidEndTime
} from '@/api/tenant/product/listDetail' //产品明细
export default {
  name: 'productListDetail',
  components: { TablePage, CDialog },
  dicts: ['bill_status'],
  data() {
    return {
      form: {},
      dialogFormVisible: false,
      //新增表单校验
      rules: {
        validEndTime: [
          {
            required: true,
            message: '到期时间不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      options: {
        check: [],
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        mutiSelect: true,
        getListApi: listTenantProductBookDetail,
        listNo: true, // 序号
        rowKey: 'productBookDetailId',
        //搜索
        search: [
          {
            label: '到期时间',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'tenantNos', label: '商户号' },
              { filter: 'tenantNames', label: '商户名称' },
              { filter: 'productNos', label: '产品编号' },
              { filter: 'productNames', label: '产品名称' }
            ]
          },
          {
            type: 'local',
            label: '状态',
            model: '',
            filter: 'lineStatus',
            seniorSearch: true,
            option: {
              clearable: true,
              data: [
                { label: '正常 ', value: 1 },
                { label: '过期', value: 2 },
                { label: '停用', value: 3 }
              ],
              label: 'label',
              value: 'value',
              filterable: true
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'stopAuthorization',
            label: '停用授权',
            type: 'primary',
            alertText: '确认要停用授权选中的产品吗？'
          },
          {
            click: 'batchUpdate',
            label: '批量修改到期时间',
            type: 'primary'
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'tenantNo',
            label: '商户号',
            minWidth: '200',
            align: 'center'
          },
          {
            prop: 'tenantName',
            label: '商户名称',
            minWidth: '200',
            align: 'center'
          },
          {
            prop: 'belongProductName',
            label: '所属产品',
            minWidth: '200',
            align: 'center'
          },
          {
            prop: 'productNo',
            label: '产品编号',
            minWidth: '200',
            align: 'center'
          },
          {
            prop: 'productName',
            label: '产品名称',
            minWidth: '200',
            align: 'center'
          },
          {
            prop: 'lineTypeName',
            label: '购买类型',
            minWidth: '200',
            align: 'center'
          },
          {
            prop: 'lineStatusName',
            label: '状态',
            minWidth: '200',
            align: 'center'
          },
          {
            prop: 'productCalcMode',
            label: '计费模式',
            minWidth: '160',
            align: 'center'
          },
          {
            prop: 'validBegTime',
            label: '开始使用时间',
            minWidth: '160',
            align: 'center'
          },
          {
            prop: 'validEndTime',
            label: '结束使用时间',
            minWidth: '160',
            align: 'center'
          },
          {
            prop: 'totalBuyProductMoney',
            label: '累计购买金额(元)',
            minWidth: '160',
            align: 'center'
          },
          {
            prop: 'totalBuyProductQty',
            label: '累计购买数量',
            minWidth: '160',
            align: 'center'
          },
          {
            prop: 'totalBuyProductDays',
            label: '累计购买天数',
            minWidth: '160',
            align: 'center'
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: '160',
            align: 'center'
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'stopAuthorization':
          try {
            let statusNo = this.options?.check
              ?.map(item => {
                if (item.lineStatus == 0) {
                  return item.productBookDetailId
                }
              })
              .filter(item => item != undefined)
            if (statusNo.length <= 0) {
              return this.$message.error('请选择状态为正常的产品进行停用')
            }
            await stopUsingByProductBookDetail(statusNo)
            await this.$refs.tablePage.getList()
            this.$message.success('停用成功')
          } catch (error) { }
          break
        case 'batchUpdate':
          this.dialogFormVisible = true
          break
        default:
          break
      }
    },
    //新增取消
    cancel() {
      this.reset()
      this.dialogFormVisible = false
    },
    // 表单重置
    reset() {
      this.form = {}
      this.resetForm('form')
    },
    async handSubmite() {
      await this.$refs['form'].validate(async va => {
        if (va) {
          let productBookDetailIdArr = this.options?.check
            ?.map(item => {
              // if (item.lineStatus === 1) {
              return item.productBookDetailId
              // }
            })
            .filter(item => item != undefined)
          // if (productBookDetailIdArr.length <= 0) {
          //   return this.$message.error('请选择状态为正常的产品进行修改')
          // }
          this.form = {
            productBookDetailIdItems: productBookDetailIdArr,
            validEndTime: this.form.validEndTime
          }
          await batchUpdateValidEndTime(this.form)
          this.dialogFormVisible = false
          this.reset() //对话框重置
          this.$message.success('修改成功')
          await this.$refs.tablePage.getList()
        }
      })
    }
  }
}
</script>
