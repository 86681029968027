<template>
  <!-- 商户产品查看明细 -->
  <div class="tenant-list" id="wrap">
    <cardTitleCom cardTitle="商户产品查看明细列表">
      <template slot="cardContent">
        <!-- 表格区域 -->
        <div class="table">
          <el-table
            :data="tableData"
            :height="tableHeight"
            :max-height="tableHeight"
            border
            style="width: 100%"
            v-loading="loading"
            ref="table"
          >
            <el-table-column
              fixed
              type="index"
              label="序号"
              width="50"
              align="center"
            >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              :prop="item.prop"
              :label="item.title"
              :min-width="item.width"
              v-for="item in tableFields"
              :key="item.title"
              align="center"
            >
              <template slot-scope="{ row }">
                <span v-if="item.text">
                  {{ item.text(row) }}
                </span>
                <span v-else>{{ row[item.prop] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { productDetailList } from '@/api/tenant/product/list.js' //接口api

export default {
  name: 'TenantList',
  components: { cardTitleCom },
  data () {
    return {
      tenantData: [], //商户数据
      loading: false, //遮罩层
      tableData: [],
      //表格字段(默认商品表格字段)
      tableFields: [
        { prop: 'tenantNo', title: '商户号', width: '200' },
        { prop: 'tenantName', title: '商户名称', width: '200' },
        { prop: 'belongProductName', title: '所属产品', width: '200' },
        { prop: 'productNo', title: '产品编号', width: '200' },
        { prop: 'productName', title: '产品名称', width: '200' },
        { prop: 'lineTypeName', title: '购买类型', width: '200' },
        { prop: 'lineStatusName', title: '状态', width: '200' },
        { prop: 'productCalcMode', title: '计费模式', width: '160' },
        { prop: 'validBegTime', title: '开始使用时间', width: '160' },
        { prop: 'validEndTime', title: '结束使用时间', width: '160' },
        {
          prop: 'totalBuyProductMoney',
          title: '累计购买金额(元)',
          width: '160'
        },
        { prop: 'totalBuyProductQty', title: '累计购买数量', width: '160' },
        // { prop: 'totalUseProductQty', text: '套', title: '累计使用数量', width: '160' },
        // { prop: 'usableProductQty', text: '套', title: '产品可用数量', width: '160' },
        { prop: 'totalBuyProductDays', title: '累计购买天数', width: '160' }
      ],
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 70 // 分页组件高度
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 60
    }
  },
  async created () {
    await this.getList()
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //获取列表数据
    async getList () {
      this.loading = true
      try {
        let res = await productDetailList({
          productBookId: this.$route.query.productBookId
        })
        this.tableData = res.data
        this.loading = false
      } catch {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tenant-list {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .navSearch {
    padding: 10px;

    .item {
      margin-right: 10px;

      .el-input,
      .el-select {
        width: 170px;
      }
    }
  }

  .table {
    padding: 10px;
  }
}
</style>
