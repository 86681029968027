<template>
  <!-- 操作(新增/编辑)系统产品功能弹窗 -->
  <div class="handleCompareDialog">
    <el-dialog
      :title="title"
      :visible.sync="openDialog"
      v-dialogDrag="true"
      width="30%"
      :before-close="handleClose"
    >
      <div class="content" v-loading="loading">
        <el-form label-width="80px" :model="form" :rules="rules" ref="form">
          <el-form-item label="功能名称" prop="compareFuncName">
            <el-input
              v-model="form.compareFuncName"
              placeholder="请输入功能名称"
            />
          </el-form-item>
          <el-form-item label="上级功能">
            <treeselect
              element-loading-text="数据加载中"
              v-model="form.parentId"
              :options="compareList"
              :show-count="true"
              placeholder="请选择上级功能"
              :normalizer="normalizer"
            />
          </el-form-item>
          <el-form-item label="排序">
            <el-input-number
              v-model="form.sortNo"
              controls-position="right"
              :min="1"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="4"
              placeholder="请输入备注"
            />
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect' //树形结构下拉框
import '@riophae/vue-treeselect/dist/vue-treeselect.css' //树形结构下拉框样式
import {
  saveCompare, //新增
  updateCompare, //修改
  compareDetail, //详情
  compareTreeList //上级tree列表
} from '@/api/tenant/product/compare' //接口api
export default {
  name: 'HandleCompareDialog',
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    //功能id
    compareFuncId: {
      type: String,
      default: undefined
    }
  },
  components: { Treeselect },
  data () {
    return {
      loading: false, //遮罩层
      form: {
        compareFuncId: undefined, //功能id
        compareFuncName: undefined, //功能名称
        parentId: undefined, //父级id
        sortNo: 1, //排序
        remark: undefined //备注
      }, //表单
      title: '新增产品功能',
      compareList: [], //上级列表
      rules: {
        compareFuncName: [
          {
            required: true,
            message: '请输入功能名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    //判断新增/编辑
    openDialog: {
      async handler (val) {
        if (val) {
          this.getCompareTreeList()
          if (this.compareFuncId !== undefined) {
            this.loading = true
            this.title = '编辑产品功能'
            const res = await compareDetail(this.compareFuncId)
            //父级id为0时,初始化undefined
            if (res.data.parentId == 0) res.data.parentId = undefined
            this.form = res.data
            this.loading = false
          } else {
            this.title = '新增产品功能'
          }
        }
      },
      immediate: true
    }
  },
  methods: {
      //下拉树结构数据转换
      normalizer (node) {
      return {
        id: node.compareFuncId,
        label: node.compareFuncName,
        children: node.productCompareResps
      }
    },
    //获取上级tree列表
    async getCompareTreeList () {
      try {
        this.compareList = await compareTreeList()
      } catch {}
    },
    //弹窗关闭的回调
    handleClose () {
      this.form = {
        compareFuncId: undefined, //功能id
        compareFuncName: undefined, //功能名称
        parentId: undefined, //父级id
        sortNo: 1, //排序
        remark: undefined //备注
      } //表单
      this.$emit('update:openDialog', false)
    },
    //提交表单
    submitForm () {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (!this.form.parentId) this.form.parentId = 0 //没有上级默认0
          if (this.compareFuncId === undefined) {
            try {
              await saveCompare(this.form)
              this.$message.success('新增成功')
              this.handleClose()
              this.$emit('getList')
            } catch {}
          } else {
            try {
              await updateCompare(this.form)
              this.$message.success('编辑成功')
              this.handleClose()
              this.$emit('getList')
            } catch {}
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.handleCompareDialog {
  ::v-deep .el-dialog {
    max-width: 600px !important;
    min-width: 600px !important;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 0px !important;
  }
  .btn {
    text-align: right;
    padding: 10px 0;
  }
}
</style>
