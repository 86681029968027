var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tenant-list", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "商户产品查看明细列表" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "table" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      height: _vm.tableHeight,
                      "max-height": _vm.tableHeight,
                      border: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        fixed: "",
                        type: "index",
                        label: "序号",
                        width: "50",
                        align: "center",
                      },
                    }),
                    _vm._l(_vm.tableFields, function (item) {
                      return _c("el-table-column", {
                        key: item.title,
                        attrs: {
                          "show-overflow-tooltip": "",
                          prop: item.prop,
                          label: item.title,
                          "min-width": item.width,
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  item.text
                                    ? _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.text(row)) + " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(row[item.prop])),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }