<template>
  <!-- 商户产品 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"></TablePage>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { productList } from '@/api/tenant/product/list.js' //接口api
import TablePage from '@/components/tablePage/index.vue'
import { AllTenantAPI } from '@/api/system/tenant'
import { productListTree } from '@/api/tenant/product/saas' //所有商户

export default {
  name: 'TenantList',
  components: { TablePage, cardTitleCom, seniorSearch, TablePagination },
  dicts: [
    'product_calc_mode',
    'coupon_mode' //优惠券形式'coupon_case_type' //优惠券类型
  ],
  data () {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          productName: undefined, //产品名称
          tenantName: undefined //商户名称
        },
        getListApi: productList,
        title: '商户产品',
        rowKey: 'productBookId',
        labelWidth: '100',
        search: [
          {
            type: 'local',
            label: '商户号/名称',
            labelWidth: '100',
            model: '',
            filter: 'tenantId',
            option: {
              clearable: true,
              remote: AllTenantAPI,
              downLoad: 15,
              label: 'tenantName',
              value: 'tenantId',
              filterable: false,
              labels: [
                { title: '编号', label: 'tenantNo' },
                { title: '名称', label: 'tenantName' }
              ]
            }
          },
          {
            type: 'remote',
            label: '产品编号/名称',
            labelWidth: '100',
            model: '', //
            filter: 'productId',
            option: {
              clearable: true,
              remote: productListTree,
              downLoad: 15,
              label: 'productName',
              value: 'productId',
              filterable: true,
              labels: [
                { title: '编号', label: 'productId' },
                { title: '名称', label: 'productName' }
              ]
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        operates: {
          width: 120,
          list: [
            {
              text: '查看明细',
              method: async row => {
                await this.$router.push({
                  path: '/tenant/product/list/components/handleProductDetail',
                  query: {
                    productBookId: row.productBookId
                  }
                })
              }
            }
          ]
        },
        columns: [
          { prop: 'tenantNo', label: '商户号', minWidth: '200' },
          { prop: 'tenantName', label: '商户名称', minWidth: '200' },
          { prop: 'belongProductName', label: '所属产品', width: '200' },
          { prop: 'productNo', label: '产品编号', minWidth: '200' },
          { prop: 'productName', label: '产品名称', minWidth: '200' },
          { prop: 'productCalcModeName', label: '计费模式', minWidth: '160' },
          { prop: 'usableProductQty', label: '可用数量', width: '160' },
          {
            prop: 'totalBuyProductMoney',
            label: '累计购买总金额(元)',
            minWidth: '160'
          },
          {
            prop: 'totalBuyProductQty',
            label: '累计购买总数量',
            minWidth: '160'
          },
          {
            prop: 'totalUseProductQty',
            label: '累计使用总数量',
            minWidth: '160'
          },
          {
            prop: 'totalBuyProductDays',
            label: '累计购买总天数',
            minWidth: '160'
          }
        ],
        list: []
      }
    }
  }
}
</script>
